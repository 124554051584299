.image-effect-wrap {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.image-effect-circle {
  background: plum;
  width: 80%;
  border-radius: 40% 60% 70% 30% / 40% 50% 60% 50%;
  overflow: hidden;
  animation: morph 4s linear infinite;
}

.image-effect-circle img {
  width: 100%;
}


@keyframes morph{
  0%, 100% {
  border-radius: 40% 60% 70% 30% / 40% 40% 60% 50%;
  }
  34% {
    border-radius: 70% 30% 50% 50% / 30% 30% 70% 70%;
  }
  67% {
    border-radius: 100% 60% 60% 100% / 100% 100% 60% 60%;
  }
}
