.bg-contact-form {
  background-size: 300% 300%;
  background-image: linear-gradient(-45deg, #FF7D41 0%, #FF7D41 10%, #FF7D41 20%, #f37660 30%, #ff865e 40%, #ff8e71 50%, #ff9783 60%, #FF8B5C 70%, #FF8B5C 80%, #FF7D41 90%, #FF7D41 100%);
  -webkit-animation: AnimateBG 20s ease infinite;
  animation: AnimateBG 20s ease infinite;
}

@-webkit-keyframes AnimateBG {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

@keyframes AnimateBG {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}
